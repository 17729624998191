  @import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&family=Poppins:wght@400;500;600;700&display=swap');

:root {
  --primary-color: #3498db;
  --secondary-color: #f39c12;
  --success-color: #2ecc71;
  --danger-color: #e74c3c;
  --warning-color: #f1c40f;
  --info-color: #3498db;
  --light-color: #ecf0f1;
  --dark-color: #34495e;
  --text-color: #333333;
  --background-color: #f0f4f8;
}

/* Base Styles */
body {
  font-family: 'Roboto', sans-serif;
  background-color: var(--background-color);
  color: var(--text-color);
  line-height: 1.6;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  margin-bottom: 1rem;
}

/* Layout */
.container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1rem;
}

/* Header Styles */
header {
    height: 70px; /* Sabit bir yükseklik belirleyelim */
    box-shadow: 0 2px 10px rgba(0,0,0,0.1);
    backdrop-filter: blur(5px);
    background-color: rgba(255, 255, 255, 0.98);
    transition: all 0.3s ease;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 50;
  }

  /* Main Content Padding */
  main {
    padding-top: 90px; /* header height + extra space */
    min-height: calc(100vh - 70px); /* viewport height - header height */
  }

/* Custom Classes */
.btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem 1rem;
  font-weight: 500;
  border-radius: 0.375rem;
  transition: all 0.2s;
}
  /* Member.js içindeki container için */
.content-container {
    max-width: 1280px;  /* max-w-7xl */
    margin: 0 auto;
    padding: 1.5rem;    /* p-6 */
    margin-top: 90px;   /* header height + extra space */
  }
    @media (max-width: 640px) {
    .content-container {
      padding: 1rem;
      margin-top: 80px;
    }
  }
  
.btn-primary {
  background-color: var(--primary-color);
  color: white;
}

/* Form Styles */
.form-group {
  @apply mb-4;
}

.form-label {
  @apply block text-gray-700 text-sm font-bold mb-2;
}

.form-input, .form-select {
  @apply block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring focus:ring-blue-500 focus:ring-opacity-50;
  transition: all 0.2s ease-in-out;
}

.form-input:focus, .form-select:focus {
  transform: translateY(-1px);
}

.form-select {
  @apply cursor-pointer;
}

/* Card Styles */
.card {
  @apply bg-white shadow-md rounded-lg overflow-hidden transition-all duration-300;
}

.card:hover {
  @apply shadow-lg;
  transform: translateY(-2px);
}

.card-header {
  @apply bg-gray-50 px-6 py-4 border-b border-gray-200;
}

.card-body {
  @apply p-6;
}

/* Button Styles */
.btn {
  @apply inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium transition-all duration-200;
}

.btn-primary {
  @apply text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500;
}

.btn-secondary {
  @apply text-white bg-gray-600 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500;
}

.btn-danger {
  @apply text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500;
}

/* BANT Form Specific Styles */
.bant-section {
  @apply mb-8 p-6 rounded-lg;
  transition: all 0.3s ease;
}

.bant-section:hover {
  transform: translateY(-2px);
}

.budget-section {
  @apply bg-blue-50;
}

.authority-section {
  @apply bg-green-50;
}

.need-section {
  @apply bg-yellow-50;
}

.timeline-section {
  @apply bg-red-50;
}

/* Checkbox Styles */
.form-checkbox {
  @apply rounded border-gray-300 text-blue-600 shadow-sm focus:border-blue-500 focus:ring focus:ring-blue-500 focus:ring-opacity-50;
}

/* Need Options Grid */
.need-options-grid {
  @apply grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-3;
}

.need-option {
  @apply flex items-center p-3 bg-white rounded-md shadow-sm hover:bg-gray-50 transition-all duration-150;
}

/* Score Summary Cards */
.score-summary-grid {
  @apply grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4;
}

.score-card {
  @apply bg-white rounded-lg shadow p-4 text-center hover:shadow-lg transition-all duration-200;
}

.score-value {
  @apply mt-2 text-3xl font-semibold;
}

/* Footer Styles */
footer {
  @apply bg-gray-100 py-4 text-center text-gray-600 text-sm;
  border-top: 1px solid rgba(229, 231, 235, 0.8);
  position: relative;
  bottom: 0;
  width: 100%;
  height: 3rem;
}
/* Loading Animations */
@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@keyframes bounce {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
}

.loading-container {
  background: linear-gradient(-45deg, #EEF2FF, #E0E7FF, #DBEAFE, #EFF6FF);
  background-size: 400% 400%;
  animation: gradient 15s ease infinite;
}

.loading-dot {
  animation: bounce 0.5s cubic-bezier(0.5, 0.05, 1, 0.5) infinite;
  animation-direction: alternate;
}

.loading-dot:nth-child(2) {
  animation-delay: 0.15s;
}

.loading-dot:nth-child(3) {
  animation-delay: 0.3s;
}

/* Loading State */
.loading-spinner {
  @apply flex items-center justify-center p-12;
}

.loading-spinner::after {
  content: '';
  @apply w-12 h-12 border-4 border-blue-500 border-t-transparent rounded-full animate-spin;
}
/* Alert Styles */
.alert {
  @apply rounded-md p-4 mb-4;
}

.alert-success {
  @apply bg-green-50 text-green-800 border border-green-200;
}

.alert-error {
  @apply bg-red-50 text-red-800 border border-red-200;
}

.alert-warning {
  @apply bg-yellow-50 text-yellow-800 border border-yellow-200;
}

/* Animation */
@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

.fade-in {
  animation: fadeIn 0.5s ease-in;
}

/* Responsive Design */
@media (max-width: 640px) {
  .btn {
    @apply w-full justify-center;
  }
  
  header h1 {
    font-size: 1.5rem;
  }

  .card-body {
    @apply p-4;
  }

  .score-summary-grid {
    @apply grid-cols-1;
  }
}

/* Custom Scrollbar */
::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  @apply bg-gray-400 rounded-full;
}

::-webkit-scrollbar-thumb:hover {
  @apply bg-gray-500;
}

/* Transition Effects */
.transition-all {
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

/* Form Section Transitions */
.form-section-enter {
  opacity: 0;
  transform: translateY(10px);
}

.form-section-enter-active {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 300ms, transform 300ms;
}

.form-section-exit {
  opacity: 1;
  transform: translateY(0);
}

.form-section-exit-active {
  opacity: 0;
  transform: translateY(-10px);
  transition: opacity 300ms, transform 300ms;
}

/* Tooltip Styles */
.tooltip {
  @apply relative inline-block;
}

.tooltip .tooltip-text {
  @apply invisible absolute z-10 p-2 text-sm text-white bg-gray-900 rounded-md opacity-0 transition-opacity duration-300;
  width: 120px;
  bottom: 100%;
  left: 50%;
  margin-left: -60px;
}

.tooltip:hover .tooltip-text {
  @apply visible opacity-100;
}
.markdown-content {
  line-height: 1.6;
}

.markdown-content h1 {
  font-size: 2em;
  font-weight: bold;
  margin-bottom: 1em;
  padding-bottom: 0.5em;
  border-bottom: 1px solid #eee;
}

.markdown-content h2 {
  font-size: 1.5em;
  font-weight: bold;
  margin-top: 1.5em;
  margin-bottom: 0.75em;
}

.markdown-content h3 {
  font-size: 1.25em;
  font-weight: bold;
  margin-top: 1.5em;
  margin-bottom: 0.75em;
}

.markdown-content ul {
  list-style-type: disc;
  margin-left: 1.5em;
  margin-bottom: 1em;
}

.markdown-content li {
  margin-bottom: 0.5em;
}

.markdown-content p {
  margin-bottom: 1em;
}

.markdown-content code {
  background-color: #f5f5f5;
  padding: 0.2em 0.4em;
  border-radius: 3px;
  font-family: monospace;
}